import "ui/styles.css";
import "@capterra/vendor-ui-styles/dist/base.css";
import {
  ClientLoaderFunctionArgs as CLFA,
  Outlet,
  useLoaderData,
} from "@remix-run/react";
import {
  json,
  type LoaderFunctionArgs,
  type LinksFunction,
  type Session,
} from "@remix-run/node";
import {
  SessionType,
  cookieSession,
  getSession,
} from "app/utils/sessions.server";
import { authenticate } from "app/utils/authenticate.server";
import { decodeToken } from "react-jwt";
import { getUserData } from "app/utils/userDataCache.server";
import { getUserIdFromAccessToken } from "app/utils/tokens.server";
import { loadUserData } from "app/utils/userData.server";
import { Resource, ServiceState } from "app/types/permissions";
import {
  CampaignSummary,
  Profile,
  UserDataOrganization,
} from "app/types/userData";
import {
  deleteImpersonation,
  popImpersonation,
} from "app/utils/impersonationCookie";
import type { PPCCampaignType } from "~/context/CurrentCampaignContext/types";
import { useDatadogContext } from "app/hooks/useDatadogContext";
import { VPFLayout } from "app/components/layout";
import ProductionBanner from "app/components/productionBanner/productionBanner";
import { isServer } from "~/utils/isBrowser";
import { Providers } from "app/components/providers";
import { type NavConfigType, getNavConfig } from "app/utils/nav.server";
import { useEffect, useState } from "react";
import {
  clearGa4DataLayer,
  clearPendoDataLayer,
  ga4Initialize,
  pendoInitialize,
} from "app/utils/tracking";
import { useFeatureFlags } from "~/hooks/featureFlags";
import { createLaunchDarklyProvider } from "app/utils/launchDarkly.client";
import { TrackingContext } from "app/hooks/useTracking";

export const links: LinksFunction = () => [
  {
    rel: "preload",
    href: "https://cdn0.capterra-static.com/assets/fonts/graphik/Graphik-Regular.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: "https://cdn0.capterra-static.com/assets/fonts/graphik/Graphik-Medium.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: "https://cdn0.capterra-static.com/assets/fonts/graphik/Graphik-Semibold.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: "https://cdn0.capterra-static.com/assets/fonts/graphik/Graphik-Bold.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous",
  },
];

const isUnauthenticatedRoute = (pathname: string) => {
  return (
    pathname === "/login" ||
    pathname.startsWith("/impersonations") ||
    pathname === "/oauth2/idpresponse" ||
    pathname.startsWith("/passwords/reset") ||
    pathname.startsWith("/activate") ||
    pathname.startsWith("/forgot_login") ||
    pathname.startsWith("/logout")
  );
};

const getSessionData = async (
  request: Request,
  session: Session<SessionType>,
  url: URL,
  accessToken?: string,
) => {
  const userId = getUserIdFromAccessToken(accessToken);

  let userData = await getUserData(userId);

  if (!userData) {
    userData = await loadUserData({ session }, url);
  }

  const selectedPPCCampaign = userData?.selectedResources?.ppc;
  const selectedPPLCampaign = userData?.selectedResources?.ppl;
  const campaignSummary = userData?.campaignSummary;

  const navConfig = await getNavConfig({ request, userData });

  return {
    userId,
    profile: userData?.profile,
    enterpriseVendorId: userData?.enterpriseVendorId,
    isLoggedIn: userData?.isLoggedIn,
    organizations: userData?.organizations,
    permissions: userData?.permissions,
    selectedResources: userData?.selectedResources,
    flags: userData?.flags,
    campaignSummary,

    selectedPPCCampaign: selectedPPCCampaign
      ? {
          ...selectedPPCCampaign,
          isUpgraded: selectedPPCCampaign?.program === "upgraded",
          isAutobidder: campaignSummary?.autobidder,
          isOneBid: campaignSummary?.oneBid,
          isPPCServices: campaignSummary?.isServices,
        }
      : null,
    selectedPPLCampaign,
    navConfig,
  };
};

const getSessionDetails = async (
  cookieHeader: string | null,
  request: Request,
  headers = new Headers(),
) => {
  const url = new URL(request.url);

  const session = await getSession(cookieHeader);
  const transientSession = await cookieSession.getSession(cookieHeader);
  const routeAfterLogin = transientSession.get("routeAfterLogin") ?? "/";
  const optOutTokenRoute =
    url.pathname.startsWith("/notifications/opt-outs") &&
    Object.keys(session.data).length === 0;

  if (isUnauthenticatedRoute(url.pathname) || optOutTokenRoute) {
    const navConfig = await getNavConfig({ request });

    return {
      routeAfterLogin,
      challengeName: session.get("challengeName"),
      challengeToken: session.get("challengeToken"),
      navConfig,
    };
  }

  const { accessToken, idToken, refreshToken } = await authenticate(
    request,
    session,
    headers,
  );

  const decodedId = idToken ? decodeToken<{ email: string }>(idToken) : null;
  const email = decodedId?.email;
  const sessionData = await getSessionData(request, session, url, accessToken);

  return {
    accessToken,
    refreshToken,
    idToken,
    email,
    challengeName: session.get("challengeName"),
    challengeToken: session.get("challengeToken"),
    employeeId: session.get("employeeId"),
    selectedProductId: session.get("selectedProductId"),
    routeAfterLogin,
    ...sessionData,
  };
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const cookieHeader = request.headers.get("Cookie");
  const cookie = (await popImpersonation(request)) || {};
  const headers = new Headers();
  const url = new URL(request.url);

  if (
    !url.pathname.startsWith("/impersonations") &&
    url.pathname !== "/oauth2/idpresponse"
  ) {
    deleteImpersonation(headers);
  }

  return json(
    {
      cookie,
      session: await getSessionDetails(cookieHeader, request, headers),
    },
    { headers },
  );
};

// persist initial launchdarkly provider
let LaunchDarklyProvider: Awaited<
  ReturnType<typeof createLaunchDarklyProvider>
> = null!;

export const clientLoader = async ({ serverLoader }: CLFA) => {
  const serverData = await serverLoader<typeof loader>();

  if (LaunchDarklyProvider == null) {
    LaunchDarklyProvider = await createLaunchDarklyProvider(
      serverData.session as LoaderType["session"],
    );
  }

  return {
    ...serverData,
    LDProvider: LaunchDarklyProvider,
  };
};

clientLoader.hydrate = true;

export const HydrateFallback = () => null;

export type SessionDetails = {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  email: string;
  userId: string;
  challengeName?: string;
  challengeToken?: string;
  employeeId?: number;
  routeAfterLogin: string;
  enterpriseVendorId: number;
  isLoggedIn: boolean;
  selectedPPCCampaign: PPCCampaignType | null;
  selectedPPLCampaign: Resource | null;
  selectedProductId: string | null;
  profile?: Profile;
  organizations: UserDataOrganization[];
  permissions?: Record<string, ServiceState>;
  selectedResources?: Record<string, Resource | null | undefined>;
  campaignSummary?: CampaignSummary;
  navConfig: NavConfigType;
};

export type LoaderType = {
  session: SessionDetails;
  LDProvider: JSX.ElementType;
};

export default function VendorPortalLayout() {
  const { session, LDProvider } = useLoaderData() as LoaderType;
  const [isTrackingInitialized, setIsTrackingInitialized] = useState(false);

  useDatadogContext();

  useEffect(() => {
    if (session.isLoggedIn) {
      const dataForTracking = {
        selectedPPCCampaign: session?.selectedPPCCampaign,
        selectedPPLCampaign: session?.selectedPPLCampaign,
        user: {
          enterpriseVendorId: String(session?.enterpriseVendorId),
          employeeId: session?.employeeId,
          isLoggedIn: session?.isLoggedIn,
          organizations: session?.organizations,
          userId: session?.userId,
          campaignSummary: session?.campaignSummary,
          profile: session.profile,
          permissions: session.permissions,
        },
        visiblePPCCampaigns:
          session.permissions?.ppc.resources
            .filter(
              (res) => res.program === "upgraded" || res.program === "draft",
            )
            ?.sort((resource1, resource2) =>
              resource1.name.localeCompare(resource2.name),
            ) ?? [],
        visiblePPLCampaigns:
          session?.permissions?.ppl.resources?.sort((resource1, resource2) =>
            resource1.name.localeCompare(resource2.name),
          ) ?? [],
      };

      clearGa4DataLayer();
      ga4Initialize(dataForTracking);
      clearPendoDataLayer();
      pendoInitialize(dataForTracking);
      setIsTrackingInitialized(true);
    }
  }, [session]);

  if (!isServer()) {
    const sessionProdBanner = sessionStorage.getItem("showProdBanner");
    if (
      (sessionProdBanner === null ||
        JSON.parse(sessionProdBanner)?.vendorId !==
          session.enterpriseVendorId) &&
      session.isLoggedIn &&
      session.employeeId
    )
      sessionStorage.setItem(
        "showProdBanner",
        JSON.stringify({
          banner: true,
          vendorId: session.enterpriseVendorId,
        }),
      );
  }

  return (
    <TrackingContext.Provider value={{ isTrackingInitialized }}>
      <LDProvider>
        <IdentifyLaunchDarklySession />
        <Providers>
          {!isServer() && session.isLoggedIn && session.employeeId && (
            <ProductionBanner session={session} />
          )}
          <VPFLayout>
            <Outlet />
          </VPFLayout>
        </Providers>
      </LDProvider>
    </TrackingContext.Provider>
  );
}

function IdentifyLaunchDarklySession() {
  const { session } = useLoaderData() as LoaderType;
  const { identify, identifyAnonymous } = useFeatureFlags();

  useEffect(() => {
    if (session.userId) {
      identify({
        userId: session.userId,
        employeeId: String(session.employeeId),
        organizations: session.organizations,
        vendorId: session?.enterpriseVendorId
          ? String(session.enterpriseVendorId)
          : undefined,
        ppcCampaignId: session?.selectedPPCCampaign?.id
          ? String(session.selectedPPCCampaign.id)
          : undefined,
        salesChannel: session.campaignSummary?.salesChannel,
      });
    } else {
      identifyAnonymous();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.userId]);

  return null;
}
